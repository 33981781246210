/*  
	-------------------------------
  	Font Import
	-------------------------------
*/

@font-face {
	font-family: 'Anton';
	src: url('../fonts/Anton-Regular.woff2') format('woff2'),
		url('../fonts/Anton-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Forma DJR';
	src: url('../fonts/FormaDJR-Regular.woff2') format('woff2'),
		url('../fonts/FormaDJR-Regular.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Forma DJR';
	src: url('../fonts/FormaDJR-Medium.woff2') format('woff2'),
		url('../fonts/FormaDJR-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}


h1, h2, h3, h4, h5, h6 {
	font-family: 'Anton' !important;
	text-transform: uppercase;
}

strong {
	font-weight: 500 !important;
}


/*  
	-------------------------------
  	Global
	-------------------------------
*/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    svg {
		display: none !important;
	}
}

.wrap {
    max-width: 1500px;
	padding: 0px;

}

.mid-wrap {
    max-width: 1300px;
    margin: 0 auto;
	padding: 0px 20px;
}

.sm-wrap {
    max-width: 1180px;
    margin: 0 auto;
	padding: 0px 20px;
}

.blog-wrap {
    max-width: 840px;
    margin: 0 auto;
	padding: 0px 20px;
}

.content-row {
	
	width: 100%;
	margin-bottom: 80px;
}

.content-row.no-margin {
	margin-bottom: 0 !important;
}

.padded-row,
.padding-y {
	padding: 80px 0px;
	
	width: 100%;
}

.margin-y {
	margin: 80px 0;
}

@media only screen and (max-width: 1200px) {
	.content-row {
		margin-bottom: 60px;
	}

	.padded-row {
		padding: 60px 0;
	}
	
}

.margin-row {
	margin-bottom: 100px;
}

.ciq-1-2 {
	width: calc(50% - 40px);
	margin-right: 80px;
	
}

.ciq-1-3 {
	width: calc(33% - 40px);
	margin-right: 80px;
	
}

.ciq-2-3 {
	width: calc(66% - 40px);
	margin-right: 80px;
	
}

.last {
	margin-right: 0px;
}

.svg-teal {
	fill: #3667ed;
}

.svg-red {
	fill: #C6563F;
}
.svg-oatmeal {
	fill:#e5fc52;
}

.slope-left-top {
	margin-bottom: -9px;
}

.ciq-oatmeal {
	background: #e5fc52;
}

.ciq-red {
	background: #C6563F;
}

.ciq-red h2, .ciq-red h4, .ciq-red p {
	color: #fff;
}

.ciq-teal {
	background: #3667ed;
}

.ciq-teal *,
.ciq-teal .simple-cta {
    color: #fff !important;
}

.svg-bottom {
	margin-top: -1px;
	margin-left: -1px;
}

.relative {
	position: relative;
}

.simple-cta {
	padding-right: 0px;
	display: flex;
	align-items: center;
}

.simple-cta:hover {
	padding-right: 15px;
}

.simple-cta::before {
    width: 20px;
    height: 2px;
    background: @yellow;
    content: "";
	display: inline-flex;
	position: relative;
    
	top: -1px;
	margin-right: 10px;
	transition: 0.3s all ease-in-out;
}

.simple-cta:hover::before {
	width: 0px;
	margin-right: 0px;
}

iframe {
	border: 0px;
}

@media only screen and (max-width: 1560px) {
	.wrap {
		max-width: 1620px;
		padding: 0px 60px;
	}
}

@media only screen and (max-width: 1260px) {
	.mid-wrap {
		max-width: 1380px;
		margin: 0 auto;
		padding: 0px 60px;
	}
}

@media only screen and (max-width: 1200px) {
	.sm-wrap {
		padding: 40px;
	}
}

@media only screen and (max-width: 979px) {
	.ciq-1-2, .ciq-1-3, .ciq-2-3 {
		width: 100%;
		
		margin: 0px 0px 40px;
	}

	.sm-wrap, .mid-wrap, .wrap {
		overflow: inherit;
		padding: 0px 40px;
	}
}

@media only screen and (max-width: 600px) {
	.wrap {
		padding: 0px 40px;
	}

	.simple-cta:hover::before {
		width: 30px;
		margin-right: 15px;
	}

	.simple-cta:hover {
		padding-right: 0px;
	}

}

@media only screen and (max-width: 461px) {
	.svg-bottom {
		position: relative;
		top: -3px;
		left: 0px;
	}
}

/*  
	-------------------------------
  	Font Styling
	-------------------------------
*/

ul, ol {
    margin: 0px 0px 30px;
    padding: 0px 0px 0px 20px;
}

h1 {
	font-family: 'Forma DJR', serif;
	font-weight: 300;
	color: #3667ed;
	font-size: 42px;
	line-height: 58px;
}

h2 {
	font-family: 'Forma DJR', serif;
	font-weight: 300;
	color: #3667ed;
	font-size: 2.9em;
	line-height: 1em;
	margin-top: 0.6em;
	margin-bottom: 0.4em;
	@media(min-width:@x-small) {}
	@media(min-width:@small) {}
	@media(min-width:@medium) {
		font-size:3.2em;
	}
	@media(min-width:@large) {}
}

h3 {
	font-family: 'Forma DJR', serif;
	font-weight: 300;
	font-size: 28px;
	line-height: 38px;
	color: #3667ed;
	margin-top: 0px;
}

h4 {
	font-family: 'Forma DJR', serif;
	font-weight: 300;
	font-size: 20px !important;
	line-height: 38px;
	color: #3667ed;
	margin-top: 0px;
}

h5 {
	font-family: 'Forma DJR', serif;
	font-weight: 100;
	font-size: 20px;
	line-height: 28px;
	color: #3667ed;
	margin: 30px 0px 0px;
}

p, span, li, a, input {
	font-family: 'Forma DJR', sans-serif;
	font-size: 1.1em;
	font-weight: 300;
	line-height: 1.4em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	letter-spacing: 0.075em;
}

ul > li {
	margin-bottom: 0.1em;
}

a {
	-webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

.site-content a {
	text-decoration: none;
	color: #3667ed;
	text-decoration: none;
	transition: 0.3s all ease-in-out;
	cursor: pointer;
}

.site-content a:hover {
	color: #17175F;
}

p {
	margin-top: 0px;
}

p a {
	font-size: 1em;
    color: #3667ed;
	text-decoration: none;
	transition: 0.3s all ease-in-out;
}

p a:hover {
	color: #352822;
}

.ta-center {
	text-align: center;
}

.simple-cta, .listen-now {
	font-family: 'Forma DJR', serif;
	font-size: 16px;
	font-weight: 300;
	color: #3667ed !important;
	text-decoration: none;
}

.oat-cta {
	color: #e5fc52 !important;
}

.listen-now:before {
	content: "";
	width: 25px;
	height: 25px;
	background-image: url(https://contentisqueen.flywheelsites.com/wp-content/uploads/2020/10/Content-is-Queen-Play-now.png); 
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	
	margin-right: 10px;
}

/*  
	-------------------------------
  	Header and Navigation styling
	-------------------------------
*/

.site-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
	z-index: 99;
	padding: 20px 0;
}

.logged-in .site-header {
	top: 32px !important;
}

.navigation-wrap {
	max-width: 1500px !important;
	width: 100% !important;
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
	align-items: center;
	padding: 20px;
}

.main-navigation {
    float: none;
    width: auto;
}

.main-navigation a,
.navigation-options a {
    display: block;
    text-decoration: none;
    color: #fff;
}

.main-navigation a {
	padding: 0;
}

.main-navigation li {
	margin: 0px 10px;
}

.navigation-left,
.navigation-right {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.site-branding {
    max-width: 220px; 
	margin-right: 20px;
	height: auto;
}

.navigation-options {
    list-style: none;
    margin: 0px;
    padding: 0;
}

.navigation-options li {
	display: inline-block;
}

.login-btn {
	background: #e5fc52;
	padding: 2px 22px !important;
	border-radius: 50px;
	text-align: center;
	color: #3667ed !important;
	margin-left: 10px;
}

.member-show {
	display: none !important;
}

.mobile-only {
	display: none !important;
}

.logged-in .member-hide {
	display: none !important;
}

.logged-in .member-show {
	display: block !important;
}



#desktop-navigation {
	display: block !important;
}

@media (min-width: @medium) {
	#desktop-navigation,
	.navigation-options,
	.mob-navigation {
		margin-top: -20px;
	}
}

#mobile-navigation {
	display: none !important;
}

/* Mobile icon */

#mobile-icon {
	position:  relative !important;
	top: auto !important;
	right: auto !important;
	height: 24px !important;
	margin-left: 10px;
	transform: scale(0.8);
}

#mobile-icon span {
	background: #fff;
}

.menuWrap {
	background-color:  #3667ed;
}

.menuWrap li a {
    font-size: 20px !important;
    font-weight: 400 !important;
}

#mobile-menu {
	margin: 0px;
	padding: 0px;
}

#mobile-menu li {
    margin-bottom: 15px;
}

#mobile-menu .menu-btn a {
	padding: 7px 30px !important;
}

#mobile-menu .menu-item a {
	display: block !important;
	color: #fff;
}

#masthead .menu-item a:after {    
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: "";
	display: block;
	height: 1px;
	left: 50%;
	position: absolute;
	background: #fff;
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	width: 0;
}

#masthead .menu-item a:hover:after, 
#masthead .current-menu-item a:after { 
	width: 100%; 
	left: 0; 
}


@media only screen and (max-width: 1040px) {
	#desktop-navigation {
		display: none !important;
	}
	
	#mobile-navigation {
		display: block !important;
	}
}

@media only screen and (max-width: 660px) {
	.navigation-options {
		display: none;
	}

	.site-branding {
		max-width: 240px; 
		margin-right: 20px;
		height: 28px;
	}
}

.mob-options {
	margin: 0 !important;
	padding: 0 !important;
	list-style: none !important;
}

.mob-options li {
    text-align: center;
}

.mob-options li:first-of-type a {
	color: #fff !important;
	margin-bottom: 15px;
	display: block;
}

.mob-options a {
	text-decoration: none;
}

/*  
	-------------------------------
  	Header / Navigation
	-------------------------------


#masthead {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
	width: 100%;
	z-index: 99;
}

.logged-in #masthead {
	top: 32px !important;
}

.navigation-wrap {
    max-width: 1500px;
    height: 100px;
    padding: 0px;
}

.site-branding {
	margin-top: 30px;
}

.site-branding img {
	width: 250px;
	margin-top: 5px;
}

#desktop-navigation {
	width: calc(100% - 310px) !important;
}

#desktop-navigation #primary-menu {
    float: right;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
    flex-wrap: wrap;
    align-items: center;
}

#desktop-navigation #primary-menu li {
	margin: 0px 10px;
}

#desktop-navigation #primary-menu li:last-of-type {
	margin-right: 0px;
}

.main-navigation {
	margin-top: 25px;
}

.main-navigation a {
    font-size: 16px;
    font-weight: 400;
	color: #fff;
	font-family: 'Work Sans', sans-serif;
	padding: 0px;
}

.btn-teal a {
	color: #3667ed !important;
}

.menu-btn a:after {
	display: none !important;
}

.menu-btn a {
	padding: 7px !important;
	border-radius: 50px;
	min-width: 150px;
	text-align: center;
}

.join-btn a, .club-btn a {
	border: 2px solid#e5fc52;
}

.club-btn {
	display: none;
}

.logged-in .club-btn {
	display: block;
}

.login-btn a {
	background: #e5fc52;
}

.login-btn a {
	color: #3667ed !important;
}

#masthead .menu-item a {
	color: #fff;
	text-decoration: none;
	display: inline-block;
	padding: 5px;
	position: relative;
	font-weight: 500 !important;
}



#mobile-icon {
	top: 37px;
}

#mobile-icon span {
	background: #e5fc52;
}





.member-show {
	display: none !important;
}

.logged-in .member-hide {
	display: none !important;
}

.logged-in .member-show {
	display: block !important;
}

.mobile-only {
	display: none;
}

@media only screen and (max-width: 1208px) {
	.mobile-only {
		display: block !important;
	}
}

#desktop-navigation .sub-menu {
    background-color: #3667ed;
    box-shadow: 0px 3px 10px -1px rgb(0 0 0 / 20%);
}

#desktop-navigation .sub-menu li {
	margin: 0px 10px !important;
}

#desktop-navigation .sub-menu li a {
    font-size: 16px !important;
    text-transform: unset;
    letter-spacing: 0px;
}

.current-menu-item .sub-menu li a:after {
    display: none !important;
}

@media only screen and (max-width: 1560px) {
	.navigation-wrap {
		max-width: 1620px;
		height: 100px;
		padding: 0px 60px;
	}
}

@media only screen and (max-width: 797px) {
	.navigation-wrap {
		padding: 0px 20px;
	}

	#masthead .menu-item a:hover:after {
		display: none;
	}
}


/*  
	-------------------------------
  	2021: Modular updates
	-------------------------------
*/

/* ---- Icon boxes ---- */

.icon-boxes {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}

.icon-boxes li {
	width: calc(50% - 20px);
	margin-right: 40px;
	margin-bottom: 40px;
	padding: 40px;
	border-radius: 15px;
	background-color: rgba(255,255,255,0.2);
	
}

.icon-boxes p:last-child {
	margin-bottom: 0 !important;
}

.icon-boxes img {
    max-height: 60px;
    margin-bottom: 15px;
}

.icon-boxes h3 {
	margin-bottom: 10px !important;
}

.icon-boxes li:nth-child(2n + 2) {
	margin-right: 0 !important;
}

@media only screen and (max-width: 979px) {
	.icon-boxes li {
		width: 100% !important;
		margin: 0 0 40px !important;
	}

	.icon-boxes li:last-of-type {
		margin-bottom: 0 !important;
	}
}

/* ---- Testimonials ---- */

.testimonials {
    margin: 0;
    padding: 0;
}

.testimonial {
	margin: 0 20px !important;
}

@media only screen and (max-width: 768px) {
	.testimonial {
		margin: 0 !important;
	}
}

/* ---- Three column ---- */

#wwd {
    display: block;
    margin: 200px 0 0;
}


/* ---- Gallery Carousel ---- */

.gallery-carousel {
    margin: 0 15px !important;
    padding: 0 !important;
	list-style: none;
}

.gallery-carousel .slick-list li {
	padding: 0 15px;
}

.gallery-img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.gallery-caption {
    display: block;
    font-size: 14px;
    border-left: 4px solid teal;
    padding: 0 10px;
    margin-top: 11px;
    line-height: 20px;
}

/* ---- Two column Text ---- */

.two-col-single-img {
    display: block;
    margin-bottom: 30px;
}

/*  
	-------------------------------
  	Join the Club
	-------------------------------
*/

.jtc-includes {
	margin: 40px 0px 0px;
	padding: 0px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.jtc-includes li {
    list-style: none;
    width: calc(33.333% - 30px);
	
	margin-right: 45px;
	margin-bottom: 45px;
}

.jtc-includes li:nth-child(3n + 3),
.jtc-includes li:last-of-type {
	margin-right: 0px;
}

.jtc-includes h3 {
	margin-bottom: 15px;
}

.jtc-icon {
    width: 80px;
    border-radius: 100%;
    margin: 0 0 20px;
}

@media only screen and (max-width: 1000px) {

	.jtc-includes li {
		list-style: none;
		width: calc(50% - 20px) !important;
		
		margin-right: 40px !important;
		margin-bottom: 40px !important;
	}

	.jtc-includes li:nth-child(2n + 2),
	.jtc-includes li:last-of-type {
		margin-right: 0px !important;
	}
}

@media only screen and (max-width: 700px) {
	.jtc-includes li {
		width: 100% !important;
		margin: 0px 0px 40px !important;
	}
}

/*  
	-------------------------------
  	Slick Overide
	-------------------------------
*/

.slick-list {
	padding: 0px !important;
}

.slick-disabled {
	opacity: 0.3;
	cursor: not-allowed !important;
}

.logo-carousel {
    margin: 0;
    padding: 0;
}

.logo-carousel .slick-slide {
	padding: 20px !important;
}

.testimonials .slick-slide {
    padding: 0 20px;
}

.testimonials .slick-track {
    margin: 0 -20px;
}

.logo-carousel .slick-dots,
.gallery-carousel .slick-dots,
.testimonial__dots .slick-dots {
	list-style: none;
	margin: 20px 0 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}

.logo-carousel .slick-dots,
.gallery-carousel .slick-dots {
	justify-content: center;
}


.logo-carousel .slick-dots li,
.gallery-carousel .slick-dots li, 
.testimonial__dots .slick-dots li {
	width: 12px;
	height: 12px;
	margin: 0 5px;
	overflow: hidden;
	background: #3667ed;
	opacity: 0.6;
	border-radius: 100%;
}

.testimonial__dots button {
	display: none;
}

.gallery-carousel .slick-dots li { 
	background: #fff !important;
}

.logo-carousel .slick-dots .slick-active,
.gallery-carousel .slick-dots .slick-active,
.testimonial__dots .slick-dots .slick-active {
	opacity: 1;
}

.logo-carousel .slick-dots button,
.gallery-carousel .slick-dots button {
	opacity: 0;
}



/*  
	-------------------------------
  	Homepage
	-------------------------------
*/

.ciq-hero {
	overflow-x: hidden;
	width: 100%;
	margin-bottom: 40px;
}

.hero {
    background-color: #3667ed;
    height: 85vh;
	width: 100%;
	overflow: hidden;
	padding-top: 40px;
}

.home .hero {
	min-height: 700px;
}

.align-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
	height: 100%;
	position: relative;
}

.hero-image {
	width: 0px;
	overflow: hidden;
	height: 60vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	min-height: 250px;
}

.hero-content {
	color: #fff;
    width: calc(50% - 20px);
    margin-left: 40px;
	opacity: 0;
}

.hero-content h1 {
	font-size: 3em;
    color: #e5fc52 !important;
	line-height: 0.95em;
    width: 100%;
	@media (min-width: @medium) {
		font-size: 4.2em;
		margin: 0 0 0.2em;
	}
}

.hero-svg {
	margin-top: -150px;
	
	width: 0px;
}

.ciq-intro {
	margin-bottom: 40px;
	
	width: 100%;
}

.pq_background {
	min-height: 400px;
    width: 90%;
    position: relative;
    right: 0;
	left: 10%;
	background-size: cover;
	background-position: center center;
}

.pq_overlay {
    position: absolute;
    bottom: 0px;
    transform: translateY(50%);
    max-width: 80%;
    text-align: center;
    padding: 20px;
    font-size: 24px;
	background: #e5fc52;
	color: #3667ed;
	font-family: 'Forma DJR', serif;
	line-height: 36px;
}

.wwd-portals {
    list-style: none;
    margin: -200px 0px 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.wwd-portals > li {
	width: calc(33.333% - 20px);
	padding: 40px 40px 120px;
	position: relative;
}

.wwd-portals > li:nth-child(1n) {
	background-color: @blue;
}

.wwd-portals > li:first-child .button {
	background: @yellow !important;
	color: @blue !important;
}

.wwd-portals > li:nth-child(2n) {
	background-color: #ff7300;
}

.wwd-portals > li:nth-child(3n) {
	background-color: #352822;
}

.wwd-portals > li:nth-child(3n) .button {
	background: @orange !important;
}

.wwd-portals > li .button {
	position: absolute;
	bottom: 40px;
}

.wwd-portals > li ul {
    margin: 0 !important;
	color: #fff !important;
}

.wwd-portals h3, .wwd-portals h5 {
	margin: 0px 0px 10px;
}

.wwd-portals > li p, .wwd-portals > li li {
    font-size: 1em;
    line-height: 1.3em;
}

.wwd-portals h3,
.wwd-portals h5, 
.wwd-portals p, 
.wwd-portals a {
	color: #fff;
}

.wwd-carousel {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.wwd-slick {
    width: calc(90% - 100px);
	
	margin-top: 40px;
}

.wwd-item {
	position: relative;
	margin: 0px 10px;
	outline: none;
	background-size: cover;
	background-position: center center;
}

.wwd-content {
    text-align: left !important;
    
    max-width: 60%;
    background:rgba(0,115,138,0.8);
	padding: 40px;
	color: #fff;
	cursor: pointer;
	position: absolute;
	bottom: 0px;
	left: 0px;
}

.wwd-overlay {
    background: rgba(0,0,0,0.3);
    height: 100%;
    width: 100%;
}

.wwd-content h3 {
	color: #fff;
	margin: 0px 0px 10px;
}

.wwd-content p { 
	color: #fff;
	margin: 0px 0px 30px;
}

.wwd-arrows {
    margin-right: 5vw;
	float: right;
	margin-top: 40px;
}

.slick-arrow {
	width: 12px;
	cursor: pointer;
}

.slick-prev {
    margin-right: 30px;
}

.cta-arrow {
    width: 55px;
	padding-right: 20px;
	transition: 0.3s all ease-in-out;
}

.wwd-content:hover .cta-arrow {
	padding-right: 0px;
	padding-left: 20px;
}

.testimonial {
    width: calc(50% - 40px);
    
    padding: 40px;
    border-radius: 20px;
	background: #e5fc52;
}

.testimonial:last-of-type {
	margin-right: 0px;
}

.logo-carousel .slick-slide {
    min-height: 100px;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
	width: 25%;
}

.logo-carousel img {
	max-width: 200px;
}


.tst-quote {
    width: 50px;
    margin-bottom: 20px;
}

.testimonial h3 {
	margin-bottom: 10px;
}

.testimonial-logo {
	max-width: 120px;
}


@media only screen and (max-width: 1300px) {
	.wwd-portals > li {
		width: calc(33.333% - 20px);
		margin-right: 30px;
		padding: 40px 40px 80px;
	}

	.wwd-portals > li:last-of-type {
		margin-right: 0px;
	}

}

@media only screen and (max-width: 1100px) {
	.wwd-portals > li {
		padding: 30px 30px 60px;
	}

	.wwd-portals > li .simple-cta {
		bottom: 30px;
	}
}

@media only screen and (max-width: @medium) {
	.hero {
		padding-top: 100px;
		height: auto;
		padding-bottom: 100px;
	}

	.hero-image {
		width: 100% !important;
		height: 300px;
	}

	.hero-content {
		position: relative;
		width: 100%;
		left: 0px !important;
		margin-left: 0px !important;
	}

	#testimonials  {
		margin-bottom: 100px;
	}

	.testimonial {
		width: 100%;
		margin: 0px 0px 40px;
	}

	.testimonial:last-of-type {
		margin-bottom: 0px;
	}

	.wwd-portals > li {
		width: 100%;
		margin: 0px 0px 40px; 
		padding: 30px 30px 60px;
	}

	.wwd-portals > li:last-of-type {
		margin-bottom: 0px;
	}

	.wwd-portals > li .simple-cta {
		bottom: 30px;
	}

	.align-center {
		display: block;
	}

	.ciq-intro {
		margin: 60px 0px 120px;
	}

	.pq_background {
		width: 100%;
		left: 0;
	}

	.pq_overlay {
		left: 10%;
	}

	.wwd-item {
		height: 400px !important;
	}

	.wwd-arrows {
		display: none;
	}

	.wwd-slick {
		width: 100%;
		padding-right: 30px;
	}

	.wwd-content {
		max-width: 80%;
		margin-top: 200px; 
	}

	.wwd-content p {
		display: none;
	}
}

@media only screen and (max-width: 650px) {
	.hero-content .hero-desc {
		margin-left: 0px;
		padding-right: 10px;
	}

	.hero-content {
		opacity: 1 !important;
	}

	.pq_overlay {
		max-width: 90%;
		left: 5%;
		font-size: 19px;
		line-height: 26px;
	}
}

/*  
	-------------------------------
  	Wanna Be Podcast CTA
	-------------------------------
*/

.podcast .sm-wrap {
	overflow: visible;
}


.wanna-be {
    position: relative;
    display: flex;
	flex-wrap: wrap;
	margin-top: 40px;
}

.wb-content {
	width: 75%;
	
	padding: 60px 25% 60px 60px;
	background-color: #e5fc52;
	border-radius: 30px 0px 0px 30px;
}

.wb-content h2, .wb-content p {
	color: #3667ed;
}

.wb-phone {
	position: absolute;
	right: calc(25% - 130px);
	width: 260px;
	bottom: 0px;
	z-index: 19;
}

.wb-pattern {
	background: url('/wp-content/uploads/2020/10/Content-is-Queen-Podcast-Pattern.jpg');
	width: 25%;
	
	border-radius: 0px 30px 30px 0px;
	background-size: cover;
}

#wwd-pocast h2 {
	margin-bottom: 0px;
}

#partners {
    
    width: 100%;
}

#partner-row {
	padding-bottom: 120px;
}

#partner-logos {
	padding-bottom: 60px;
}

.partner-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    margin: 0px;
	padding: 0px;
	align-items: center;
}

.partner-logos li {
	width: 180px;
	margin-bottom: 80px;
}

.press-logos li {
	width: 200px;
}

@media only screen and (max-width: 860px) {
	.wb-phone {
		display: none;
	}

	.wb-pattern {
		width: 15%;
		background-position: center;
	}

	.wb-content {
		width: 85%;
		padding: 40px;
	}

}

@media only screen and (max-width: 566px) {
	.wb-pattern {
		width: 100%;
		background-position: center center;
		height: 40px;
		border-radius: 0px 0px 30px 30px;
	}

	.wb-content {
		width: 100%;
		border-radius: 30px 30px 0px 0px;
	}

	.press-logos li {
		width: 100%;
		text-align: center;
	}

	.press-logos img {
		max-width: 180px;
	}

	.press-logos li:last-of-type {
		margin-bottom: 0px;
	}
}

/*  
	-------------------------------
  	Page Heading
	-------------------------------
*/

.page-head {
    position: relative;
	min-height: 600px;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background-position: center center !important;
	background: @blue;
	margin-bottom: 60px;
	background-size: cover !important;
	padding: 0px 20px;
	overflow: hidden;
}

.member-head {
	margin-bottom: 0px;
}

.page-head h1 {
    color: #e5fc52;
    position: relative;
    z-index: 25;
    max-width: 900px;
	text-align: center;
}

.page-head h2 {
    color: #e5fc52;
    position: relative;
    z-index: 25;
    max-width: 900px;
    font-size: 40px;
    text-align: center;
}

.hero-overlay {
    background: rgba(54, 103, 237, 0.4);
    height: 100%;
	width: 100%;
	z-index: 20;
	position: absolute;
}

.single-resources .hero-overlay {
	background: rgba(54, 103, 237, 0.4);
}

.svg-head {
    position: absolute;
    bottom: -1px;
	fill: #fff;
	z-index: 30;
	left: 0px;
	width: 120%;
}

.woocommerce-cart .page-head,
.woocommerce-checkout .page-head {
	display: none;
}

.woocommerce-cart #masthead,
.woocommerce-checkout #masthead,
.post-type-archive-product #masthead,
.tax-product_cat #masthead  {
	background-color: #3667ed;
	position: relative;
	margin-top: 0px;
	top: 0px !important;
}

.single-free_resources .page-head h1 {
    font-size: 38px;
    line-height: 51px;
}

@media only screen and (max-width: 797px) {
	.page-head h1 {
		font-size: 34px;
		padding: 0px 20px;
		line-height: 42px;
	}

	.page-head {
		min-height: 520px;
	}
}

/*  
	-------------------------------
  	Filtering
	-------------------------------
*/

#filters {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

#filters li {
	margin: 0px 20px 20px;
	list-style: none;
}

#filters li a {
	color: #3667ed !important;
}

#filters .active {
	border-bottom: 1px solid #3667ed;
}

.iso-container {
    max-width: 1200px;
    margin: 40px auto 0px;
}

.iso-post .search-filter-result-item {
	display: flex;
}


/*  
	-------------------------------
  	About
	-------------------------------
*/

.about-wwd h3 {
	margin-bottom: 10px;	
}

.about-wwd h5 {
	margin-top: 0px;
}

.our-team {
	list-style: none;
	margin: 0px;
	padding: 0px;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.single-team {
    
	width: calc(50% - 60px);
	margin-right: 120px;
	margin-bottom: 60px;
}

.single-team:nth-child(2n + 2),
.single-team:last-of-type {
	margin-right: 0px;
}

.single-team:last-of-type {
	margin-bottom: 0px;
}

.profile-img {
    width: 80px;
	border-radius: 100px;
	
	margin-right: 20px;
}

.about-team {
	width: calc(100% - 100px);
	
}

.team-wrap {
    
	width: 100%;
	margin-bottom: 20px;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}

.about-team h3 {
    margin-bottom: 5px;
}

.job-title {
	font-family: 'Forma DJR', serif;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	color: #ff7300;
	margin-top: 0px;
}

.wb-podcast .page-head, .single-head {
	background: rgba(198, 86, 63, 1);
}

.wb-podcast .hero-overlay {
	background: rgba(198, 86, 63, 0.7);
}

.tab-content {
	display: none;
}

.tab-content.current{
	display: block;
}

#about-tabs .tabs{
	margin: 0px;
	padding: 0px;
	list-style: none;
	
}

#about-tabs .tabs li{
	background: none;
	color: #66ABB9;
	display: inline-block;
	padding: 20px 15px;
	cursor: pointer;
	border-left: 3px solid #66ABB9;
	padding-left: 20px;
	width: 100%;
	font-family: 'Forma DJR', serif;
	font-size: 24px;
	transition: 0.22s all ease-in-out;
}


#about-tabs .tabs li.current{
	color: #3667ed;
	border-left: 3px solid #3667ed;
	background-color: #FDFAF4;
}

#about-tabs .tab-content {
    background: transparent;
    width: calc(70% - 50px);
    
}

#about-tabs .tab-content p {
	margin-top: 0px;
}

@media only screen and (max-width: 979px) {
	#about-tabs .tabs {
		width: 100%;
		margin: 0px 0px 20px;
	}

	#about-tabs .tab-content {
		width: 100%;
	}

	.our-team li {
		width: 100%;
		margin: 0px 0px 40px;
	}

	.our-team li:last-of-type {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width: 400px) {
	#about-tabs .tabs li {
		font-size: 19px;
		padding: 10px 15px !important;
	}
}


/*  
	-------------------------------
  	Shownotes / Single
	-------------------------------
*/

.single-post #masthead,
.single-show_notes #masthead {
	background-color: #3667ed;
	position: relative;
	margin: 0px 0px 40px;
	top: 0px !important;
}

.featured-image {
	width: 500px;
	margin: 0px 0px 20px;
}

.sn-title {
	margin-bottom: 0px;
}

.sn-details {
    margin: 0px 0px 20px;
    color: #222;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

#ciq-shownotes {
	margin-bottom: 0px !important;
}

.main-sn-list {
    display: flex;
    flex-wrap: wrap;
	justify-content: center;
}

.main-sn-list li {
	width: calc(33.333% - 30px);
	margin-right: 45px;
	margin-bottom: 45px;
}

.main-sn-list li:nth-child(3n + 3),
.main-sn-list li:last-of-type {
	margin-right: 0px;
}

.sn-content {
	text-align: center;
}

.sn-thumb {
    position: absolute;
	width: 450px;
	margin-top: 40px;
	max-width: calc(100% - 40px);
}

.latest-news {
	margin: 0px;
	padding: 0px;
	list-style: none;
}

.latest-news li {
	background: #3667ed;
}

.latest-news .simple-cta {
	color: #fff !important;
}

.sn-thumb img {
	opacity: 0.5;
}

.single-resources .sn-thumb img {
	opacity: 1;
}

.ln-head .sn-details {
    color: #fff !important;
    opacity: 1;
}

.pagination {
    display: flex;
	justify-content: space-between;
	margin-top: 60px;
	width: 100%;
}

.previous-post {
	max-width: calc(50% - 20px);
	
}

.next-post {
	max-width: calc(50% - 20px);
}

.pagination a {
	text-decoration: none;
}

.previous-post a {
	
	width: calc(100% - 37px)
}

.sn-filtering ul {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.sn-filtering li {
    max-width: 250px;
} 

.sn-filtering input, .sn-filtering select {
    background: #e5fc52 !important;
	padding: 10px !important;
	border-radius: 10px !important;
	border: 0px !important;
}

.search-filter-results-list h3 {
	text-transform: capitalize;
}

#ciq-resources .search-filter-results-list {
    text-align: left;
}

#ciq-resources h3 {
	margin-bottom: 0px;
}

#ciq-resources p {
	margin-top: 0px;
}

.resource-author {
    color: #777 !important;
    font-size: 13px;
    margin: 0px 0px 15px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

@media only screen and (max-width: 1050px) {
	.main-sn-list li {
		width: calc(50% - 25px) !important;
		margin-right: 50px !important;
		margin-bottom: 50px !important;
	}

	.main-sn-list li:nth-child(2n + 2),
	.main-sn-list li:last-of-type {
		margin-right: 0px !important;
	}
}

@media only screen and (max-width: 797px) {
	.main-sn-list li {
		width: 100% !important;
		margin-right: 0px !important;
		margin-bottom: 40px !important;
	}

	.previous-post {
		display: none;
	}

	.next-post {
		max-width: 100% !important;
	}

	.sn-rule, .sn-label {
		display: none;
	}
}

@media only screen and (max-width: 550px) {
	.sn-filtering {
		margin-bottom: 20px;
	}

	.sn-filtering li {
		width: 100%;
		max-width: 100%;
		margin: 0px 0px 10px !important;
	} 
}

/*  
	-------------------------------
  	Podcast / Wanna Be
	-------------------------------
*/

#podcast-svg {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%;
    fill: #fff;
}

.podcast-image {
	position: absolute;
	right: 0px;
    width: 320px;
    margin: 0 auto;
	display: block;
	top: -50px;
}

#the-podcast {
	overflow: hidden;
}

#the-podcast .slope-left {
	position: relative;
	z-index: 10;
}

.link-apple {
	margin-right: 20px;
}

.podcast-url {
	
	margin-top: 40px;
	cursor: pointer;
}

.podcast-url img {
    height: 50px;
}

.show-notes {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

.show-notes a {
	text-decoration: none;
}

.show-notes li {
	
	background: #C6563F;
}

.sn-preview li {
    width: calc(50% - 30px);
    
    margin-right: 60px;
}

.sn-preview li:last-of-type {
    margin-right: 0px;
}

.sn-image {
	
	width: 100%;
}

.sn-wrapper {
	padding: 25px;
	
	width: 100%;
}

.sn-wrapper h3 {
	margin-top: 0px;
}

.sn-wrapper h3, .sn-wrapper p, .sn-wrapper span {
	color: #fff;
}

.sn-info {
    font-size: 13px;
    letter-spacing: 0.05em;
}

.sn-rule {
    margin: 20px 0px;
    background: rgba(255,255,255, 0.2) !important;
}

.sn-label {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 12px;
    
    width: 100%;
    opacity: 0.7;
    padding: 5px 0px;
}

.our-members {
	margin: 40px 0px 0px;
	padding: 0px;
	list-style: none;
}

.our-members li {
	width: calc(16.667% - 15px);
	margin-right: 18px;
	
}

.our-members li:last-of-type {
	margin-right: 0px;
}

@media only screen and (max-width: 979px) {
	.sn-preview li {
		width: 100%;
		margin: 0px 0px 40px;
	}

	.sn-preview li:last-of-type {
		margin-bottom: 0px;
	}

	.our-members li {
		width: calc(33.333% - 10px);
		margin-right: 15px;
		
		margin-bottom: 15px;
	}

	.our-members li:nth-child(3n + 3) {
		margin-right: 0px;
	}

	.podcast-image {
		position: relative;
		width: 200px;
		margin: 40px auto 0px;
		display: block;
		top: 0px;
	}
}

/*  
	-------------------------------
  	Membership Portals
	-------------------------------
*/

.membership-tabs {
    list-style: none;
    margin: 0px 0px 40px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.membership-tabs li {
    padding: 10px 0px;
	margin: 0px 20px;
	color: #3667ed;
	font-family: 'Forma DJR', serif;
	border-bottom: 3px solid #3667ed;
	font-size: 26px;
	opacity: 0.6 !important;
}

.membership-tabs li:hover {
	cursor: pointer;
}

.membership-tabs .current {
	opacity: 1 !important;
}

.memberships {
    margin: 0px;
    padding: 0px;
	list-style: none;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.memberships > li {
	width: calc(33.333% - 40px);
	background-color: #fff;
	margin-right: 60px;
	margin-bottom: 60px;
	transition: 0.3s all ease-in-out;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
	box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
}

.memberships > li:nth-child(3n + 3),
.memberships > li:last-of-type {
	margin-right: 0px !important;
}

.memberships > li:hover {
	transform: scale(1.02);
	-webkit-box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.16);
	box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.16);
}

.memberships li a {
	text-decoration: none;
}

.memship-head {
	padding: 60px 20px;
	background-color: #3667ed;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.memship-svg {
    position: absolute;
    left: 0px;
    bottom: -1px;
    fill: #fff;
}

.memberships > li:nth-child(2) .memship-head,
.memberships > li:nth-child(6) .memship-head {
	background-color: #352822 !important;
}

.memberships > li:nth-child(3) .memship-head,
.memberships > li:nth-child(7) .memship-head {
	background-color: #e5fc52 !important;
}

.memberships > li:nth-child(4) .memship-head, 
.memberships > li:nth-child(8) .memship-head {
	background-color: #ff7300 !important;
}

.monthly-cost, .by-month {
	
	width: 100%;
	text-align: center;
}

.monthly-cost {
    font-size: 60px;
    margin-bottom: 10px;
	line-height: 60px;
	color: #fff;
}

.monthly-price {
    
    margin-right: 5px;
}

.by-month {
    color: #fff;
    font-size: 20px;
    letter-spacing: 0.15em;
}

.memship-info {
	padding: 20px;
	
	width: 100%;
}

.memship-info h3 {
    margin-bottom: 10px;
}

.memship-info p, 
.memship-info span, 
.memship-features li {
    font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	color: #888 !important;
}

.memship-features {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.memship-features li,
.the-features ul li {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0px 0px 15px;
}

.manyMore:before {
    background: transparent;
    display: none;
}

.manyMore {
    font-style: italic;
    margin-top: 10px;
    font-size: 16px;
}

.tick {
    width: 20px;
	height: 20px;
	margin-right: 10px;
}

.memberships .simple-cta {
	margin-top: 10px;
	
}

.central-link {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.single-feature {
	width: calc(100% - 30px);
	
    line-height: 20px !important;
    margin: -2px 0px 0px;
}

.memship-excerpt {
	min-height: 72px;
}

@media only screen and (max-width: 979px) {
	.memberships > li {
		width: calc(50% - 20px);
		margin-right: 40px;
		margin-bottom: 40px;
	}

	.memberships > li:nth-child(2n + 2) {
		margin-right: 0px;
	}
} 

@media only screen and (max-width: 700px) {
	.memberships > li {
		width: 100%;
		margin: 0px 0px 40px;
	}
} 

/*  
	-------------------------------
  	Our Work
	-------------------------------
*/

.services {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.services li {
    
	margin: 0px 0px 120px;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.services li:last-of-type {
	margin-bottom: 0px !important;
}

.services-thumb {
    width: calc(40% - 40px);
	
}

.odd .services-thumb {
	margin-right: 80px;
}

.even .services-thumb {
	margin-left: 80px;
}

.services-desc {
    
    width: calc(60% - 40px);
    margin-top: 40px;
}

@media only screen and (max-width: 979px) {

	.services li {
		display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}

	.services-thumb {
		margin: 0px !important;
	}

	.services-thumb {
		width: 100%;
		margin: 0px;
		order: 1;
	}

	.services-desc {
		width: 100%;
		order: 2;
	}
}

/*  
	-------------------------------
  	FAQs
	-------------------------------
*/

#frequent-questions .sm-wrap {
	max-width: 800px;
}

.the-content {
    
    width: 100%;
}

.accordion {
	list-style: none;
	padding: 0px;
	
	width: 100%;
	margin: 40px 0px 0px;
}

.ac-single {
	position: relative;
	border-bottom: 1px solid #e5fc52;
	
	
	width: 100%;
}

.full-faqs li:last-of-type {
	border-bottom: 0px;
}

.ac-title {
	font-family: 'Forma DJR', serif;
    font-weight: 300;
    color: #3667ed;
    cursor: pointer;
    display: block;
    padding: 20px 30px 20px 0px;
    position: relative;
    text-align: left;
	font-size: 22px;
	
	width: 100%;
}

.ac-title::after {
    width: 14px;
    height: 14px;
    border-right: 1px solid #3667ed;
    border-bottom: 1px solid #3667ed;
    position: absolute;
    right: 0px;
    content: " ";
    top: 26px;
    transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
}

.ac-title.active::after {
	transform: rotate(-135deg);
	transition: all 0.2s ease-in-out;
}

.ac-content {
	color: #000;
	display: none;
	margin: 0;
	text-align: left;
	padding-bottom: 10px;
	
	width: 100%;
}

.ac-content p {
	margin-top: 0px;
}

.ac-image {
    width: 30%;
    
    margin-right: 60px;
}

.faq-cta {
    
    width: 100%;
	margin-top: 40px;
	text-align: center;
}

.faq-cta a {
	font-family: 'Forma DJR', serif;
	font-weight: 100;
	font-size: 20px;
	line-height: 28px;
	color: #3667ed;
	margin: 30px 0px 0px;
}

/*  
	-------------------------------
  	Members Area
	-------------------------------
*/

/*  ------- Member Navigation -------------- */

#club-nav {
	display: flex;
    margin: 10px 0;
    padding: 0;
    list-style: none;
}

#club-nav li {}

#club-nav .menu-item a {
	padding: 12px 20px;
	
	text-decoration: none;
}

#club-nav .current-menu-item a {
	background-color: #e5fc52;
	border-radius: 5px;
}

.members-nav {
    
    width: 100%;
    padding: 12px 0px;
	border-bottom: 1px solid rgba(0,0,0,.1);
	margin-bottom: 80px;
}

@media only screen and (max-width: 797px) {
	.members-nav {
		margin-bottom: 40px;
	}

	#club-nav .menu-item {
		padding: 0px 5px;
	}

	#club-nav .menu-item a {
		padding: 7px 14px;
	}
}

@media only screen and (max-width: 650px) {
	#club-nav {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	#club-nav li {
		margin-bottom: 10px;
	}
}

/*  ------- Resources + Account Layout -------------- */

.filter-menu, .account-menu {
    background: #e5fc52;
    width: calc(25% - 30px);
    
    margin-right: 60px;
    padding: 40px;
    border-radius: 20px;
}

.filter-menu {
	align-self: flex-start;
}

.search-results, .account-content {
	width: calc(75% - 30px);
	
}

.searchandfilter ul,
.account-menu ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.searchandfilter ul li {
	margin: 0px 0px 20px;
}

.searchandfilter ul li:last-of-type {
	margin: 0px !important;
}

.search-filter-results-list {
    list-style: none;
    // margin: 0px;
    padding: 0px;
}

.search-filter-results-list li {
    // width: 100%;
    
    padding-bottom: 40px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 40px;
}

.asset-thumbnail {
    width: 300px;
    margin-right: 50px;
    
}

.resource-wrap {
	margin-top: 1em;
}

.searchandfilter label, 
.searchandfilter input,
.searchandfilter select {
	width: 100%;
}

.sf-field-search input {
    background: transparent;
    border: 0px;
    border-bottom: 1px solid #3667ed;
	border-radius: 0px;
	outline: none;
	font-family: 'Work Sans', sans-serif;
	color: #000;
	width: 250px;
}

.sf-field-search input::placeholder {
	color: #777;
}

.sf-input-select {
    background: transparent;
    border: 0px;
    border-bottom: 1px solid #3667ed;
	font-family: 'Work Sans', sans-serif;
	color: #000;
	padding-bottom: 5px;
	outline: none;
}

#resource-filter .sf-field-search input {
	width: 100% !important;
}

@media only screen and (max-width: 1200px) {
	.search-results, .filter-menu {
		width: 100%;
	}

	.sf-field-search input {
		width: 100%;
	}

	.filter-menu {
		margin-bottom: 40px;
	}

	.filter-menu li {
		width: calc(50% - 20px);
		
		clear: none;
		margin-right: 20px;
		margin-bottom: 0px !important;
	}

	.filter-menu li:nth-child(2n + 2) {
		margin-right: 0px;
	}

	.searchandfilter ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

@media only screen and (max-width: 797px) {
	.filter-menu {
		width: 100%;
		margin: 0px 0px 40px;
	}

	.account-menu {
		width: 100%;
		margin: 0px 0px 40px;
		max-width: 400px;
	}

	.asset-thumbnail {
		width: 100%;
		margin: 0px 0px 1em;
	}

	.resource-wrap {
		width: 100%;
		margin: 0px;
	}

	.filter-menu li {
		width: 100%;
	}

	.search-results, .account-content {
		width: 100%;
	}

	.account-form {
		width: 100% !important;
		margin: 0px 0px 20px !important;
	}

	.account-content fieldset {
		padding: 20px !important;
		margin-top: 20px !important;
	}
}

/*  ------- My Account -------------- */

.account-item {
	
	width: 100%;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.account-item a {
    text-decoration: none;
    font-size: 16px;
}

.account-content fieldset {
    padding: 30px 40px;
    background: #f7f7f7;
    border-radius: 20px;
    border: 0px;
}

.account-content fieldset legend {
    
    width: 100%;
    font-size: 30px;
	font-family: 'ITC Souvenir';
	line-height: 36px;
	margin-bottom: 10px;
}

.account-content .clear {
    display: none;
}

.account-form {
    width: calc(50% - 20px);
    
    margin-right: 40px;
    margin-bottom: 40px;
}

.acc-form-last {
	margin-right: 0px;
}

.account-form label,
.account-form input, 
.account-form span {
	width: 100%;
}

.account-edit-form {
    
    width: 100%;
    margin: 0px 0px 40px;
}

/*  ------- Start Here (/the-club) -------------- */

.central {
	text-align: center;
}

#intro-video {
    margin-bottom: -200px;
	position: relative;
	z-index: 10;
}

#club-portals {
	padding-top: 240px;
}

.portals {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

.portals li {
    width: calc(50% - 60px);
    
	background-color: #3667ed;
	text-align: center;
	margin-right: 120px;
	border-radius: 5px;
	background-size: cover;
	background-position: center center;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
	box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
	transition: 0.3s all ease-in-out;
}

.portals li:nth-child(2n) {
	margin-right: 0px;
	background-color: #ff7300;
}

.book-portal {
	background-image: url('/wp-content/uploads/2020/11/Content-is-Queen-Hero-Recording-1200x675.jpg');
}

.resources-portal {
	background-image: url('/wp-content/uploads/2020/11/CiQ_Resources_portal.jpg');
}

.portals li:hover {
	transform: scale(1.02);
	-webkit-box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.16);
	box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.16);
}

.portals a {
	text-decoration: none;
}

.portals h2 {
    margin: 0px;
    text-align: center;
	padding: 120px 20px;
	color: #fff;
}

.misc-lozenge {
    background: #3667ed;
    max-width: 720px;
	width: 100%;
    margin: 0 auto;
    padding: 40px;
	border-radius: 30px;
}

.misc-lozenge h2, .misc-lozenge h4 {
	color: #fff;
}

.support-msg {
    max-width: 640px;
    margin: 10px auto 0px;
	font-size: 14px;
	padding: 0px 20px;
    color: #3667ed;
}

.support-msg a {
	font-size: 14px;
	color: #3667ed;
	font-weight: 500;
} 

@media only screen and (max-width: 1040px) {
	.misc-lozenge {
		margin: 0px 40px;
	}
}

@media only screen and (max-width: 797px) {
	.portals li {
		width: 100%;
		margin: 0px 0px 40px;
	}

	.portals li:last-of-type {
		margin-bottom: 0px;
	}
}



/*  
	-------------------------------
  	Contact Page
	-------------------------------
*/

.contact-head {
    min-height: 500px;
	padding-bottom: 50px;
	margin-bottom: 0px;
}

.contact-form {
    max-width: 750px;
    margin: 0px auto;
    position: relative;
    z-index: 33;
    background: @yellow;
    padding: 40px;
    border-radius: 20px;
}

.contact-overlap {
	margin: -170px auto 45px !important;
}

.contact-form .nf-field-label,
.nf-before-form-content {
    display: none;
}

.nf-field-element input, .nf-field-element textarea {
    background: #fff !important;
    border-radius: 10px !important;
    border: 0px !important;
    padding: 20px !important;
}

.checkbox-wrap label, .checkbox-wrap label a{
    font-size: 15px !important;
    font-weight: 400 !important;
	font-family: 'Work Sans', sans-serif !important;
}

.submit-wrap .nf-element {
	background: #3667ed !important;
	color: #fff !important;
    padding: 0px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 5px 50px !important;
    border-radius: 50px !important;
	font-family: 'Work Sans', sans-serif !important;
	transition: 0.3s all ease-in-out !important;
	outline: none !important;
}

.submit-wrap .nf-element:hover {
	opacity: 0.8!important;
}

.nf-field-element:after {
	display: none !important;
}

@media only screen and (max-width: 800px) {
	.contact-head {
		min-height: 400px;
		padding-bottom: 120px;
	}

	.contact-overlap {
		margin: -170px 20px 45px;
	}

	.contact-form {
		max-width: 100%;
	}
}

@media only screen and (max-width: 850px) {
	.contact-form {
		margin: 0px 25px;
	}
}


@media only screen and (max-width: 550px) {
	.contact-form {
		padding: 20px;
	}
}

/*  
	-------------------------------
  	Login Page
	-------------------------------
*/

.page-id-201 .page-head {
    display: none;
}

.page-id-201 #masthead {
	background-color: #3667ed;
	position: relative;
}

.page-id-201 pre {
	margin: 40px auto;
	max-width: 500px;
}

.page-id-201 .woocommerce-LostPassword {
    margin-bottom: 0px;
}

/*  
	-------------------------------
  	Footer / Colophon
	-------------------------------
*/

.lead-gen {
	border-bottom: 1px solid rgba(255,255,255,0.2);
	margin-bottom: 3em;
}


#mc_embed_signup {
	width: 100%;
	
}


.indicates-required {
    display: none;
}

.mc-field-group {
    width: calc(50% - 20px);
	float: left;
	margin-right: 40px;
}

.mc-field-group.email {
	margin-right: 0px !important;
}

.mc-field-group label,
.mc-field-group input {
    
    width: 100%;
}

.mc-field-group label {
    color: #fff;
    margin-bottom: 10px;
}

// #mc_embed_signup_scroll {
// 	display: flex;
// 	align-items: flex-end;
// 	justify-content: space-between;
// }

#mc-embedded-subscribe {
	.button;
	.button--standard;

	margin-top: 1em;
	display: inline-flex;
}

@media only screen and (max-width: 875px) {
	.mc-field-group {
		width: 100% !important;
		margin: 0px 0px 20px !important;
	}
}

@media only screen and (max-width: 625px) {
	.lg-left-col, #mc_embed_signup {
		width: 100% !important;
		margin: 0px 0px !important;
	}

	#mc-embedded-subscribe {
		margin-top: 10px;
	}
}

#colophon {
	background-color: transparent;
	padding: 0px;
	margin: 0 !important;
}

#footer-row {
	padding-bottom: 40px !important;
	padding-top: 40px !important;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-logo {
	width: 280px;
}

.footerCol .menu {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

.foooterCol .menu-item a {
	padding: 0px;
}

#colophon h5 {
	color: #e5fc52;
	margin-top: 0px !important;
	margin-bottom: 10px;
}

.site-info {
	float: none !important;
	margin-top: 60px;
}

.site-info a {
	font-size: 12px;
}

.copyright, .web-by {
    color: #fff;
    text-decoration: none;
	opacity: 0.5;
	color: #fff !important;
	transition: 0.3s all ease-in-out;
}

.copyright:hover, .web-by:hover {
	opacity: 0.8;
}

.copyright {
	
}

.web-by {
	float: right;
}

.footerCol .menu-item a {
    padding: 0px;
    color: #fff;
    text-decoration: none;
}

.footerCol .menu-item {
	margin-bottom: 10px;
}

.social-icons {
    list-style: none;
    margin: 10px 0px 0px;
    padding: 0px;
} 

.social-icons li {
	
	width: 25px;
	margin-right: 15px;
}

.social-icons li a {
	text-decoration: none;
}

.footer-contact {
    
    width: 100%;
    margin-top: 40px;
}

.footer-contact a {
	display: block;
    text-decoration: none;
    color: #fff;
    margin-bottom: 6px;
}

#colophon p {
	color: #fff;
}

@media only screen and (max-width: 979px) {
	.footerCol:first-of-type {
		width: 100%;
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 720px) {
	.footerCol {
		width: 100%;
		margin: 0px 0px 40px;
	}
}

@media only screen and (max-width: 461px) {
	.site-info a {
		width: 100%;
		margin: 0px 0px 10px;
		text-align: center;
	}

	.site-info a:last-of-type {
		margin-bottom: 0px;
	}
}

/*  
	-------------------------------
  	Form
	-------------------------------
*/

#products .nf-form-content label {
	display: none;
}

#products input {
	background-color: #e5fc52 !important;
}

#products .textbox-wrap {
    text-align: center;
}

.widthFifty {
	width: calc(50% - 20px);
	
	clear: none !important;
	margin-right: 40px;
	margin-bottom: 40px;
}

.widthFifty.last {
	margin-right: 0px;
}

.offering {
    max-width: 700px;
    text-align: center;
    margin: 0 auto 40px;
}

#products .submit-container input {
	background: #3667ed !important;
    font-size: 16px !important;
    line-height: 16px !important;
    padding: 15px 30px !important;
    font-weight: 600;
}

@media only screen and (max-width: 850px) {
	.widthFifty {
		width: 100% !important;
		margin: 0px 0px 20px !important;
	}
}

#support {
	text-align: center;
}

.misc-lozenge {
	display: inline-block;
	text-align: left;
}

.loz-icon {
    width: 150px;
    margin-right: 40px;
    float: left;
}

.loz-content {
    width: calc(100% - 190px);
	float: left;
    
}

@media only screen and (max-width: 1040px) {
	.loz-content {
		width: 100%;
		margin-top: 40px;
	}

	.loz-icon {
		width: 100px;
		margin-right: 0px;
	}
}

/*  
	-------------------------------
  	Basic Page Template
	-------------------------------
*/

.page-template-basic #masthead {
	background: #3667ed;
}

.page-template-basic .site-content {
	margin-top: 120px;
}

.woocommerce-form__input-checkbox {
    float: left !important;
    width: 20px !important;
    margin-top: 7px;
    margin-right: 10px !important;
}


.single-product .products .columns-3 {
	display: flex;
	justify-content: space-between;
}

.single-product .products .columns-3 li {
	width: calc(33.333% - 20px);
}

.footer-cta {
	width: 100%;
}


pre {
    white-space: normal !important;
}

.woocommerce-error {
	background: rgba(226, 64, 28, 0.1);
	border-left: 2px solid #e2401c;
	padding: 2px 0.25em 0 0.5em;
	
	list-style: none;
}

.woocommerce-error li {
	padding: 0.25em;
}