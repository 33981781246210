
// reset

.button {
	outline:0;
}


// basic button style

.button {
	text-decoration:none;
	display:inline-block;
}

// button types
.button--standard {
	background: #3667ed;
	color: #fff !important;

	padding: 0 1em;
	font-weight: 500;
	border-radius: 5em;
	font-size: 1em;
    letter-spacing: 0.025em;
	line-height: 40px;

	display: inline-flex;
	align-items: center;

	height: 40px;
}

.ciq-teal .button--standard,
.ciq-hero .button--standard {
	background: #e5fc52;
	color: #3667ed !important;
}

.button--primary {}

.button--secondary {}


// modifiers

.button--block {
	display:block;
}
