
// default text link styles

a { 
    color:blue;
    text-decoration: none;
}

a:hover {}

a:active {}
