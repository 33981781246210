
// text

.text-center { text-align:center }
.text-left { text-align:left }


// margin

.margin-0 { margin:0 }
.margin-top-0 { margin-top:0 }
.margin-bottom-0 { margin-bottom:0 }
.margin-x-auto { margin-left:auto; margin-right:auto }


// list

.no-list {
	margin:0; 
	padding:0;
	list-style:none;
}


// position

.relative { position:relative }


// flex helpers

.flex-align-center, .flex-center { align-items:center }
.flex-justify-center, .flex-center { justify-content:center }
.flex-space-between { justify-content:space-between }
.flex-end { align-items:flex-end }
.flex-no-wrap { flex-wrap:nowrap }
.flex-align-baseline { align-items:baseline }
.flex-no-shrink { flex-shrink:0 }



// show/hide

.hide { display:none !important }
.inline { display:inline !important }
.inline-block { display:inline-block !important }
.block, .show { display:block !important }
.flex { display:flex !important }

.for-breakpoints({
    .hide\@@{breakpoint} { display:none !important }
    .inline\@@{breakpoint} { display:inline !important }
    .inline-block\@@{breakpoint} { display:inline-block !important }
    .block\@@{breakpoint}, .show\@@{breakpoint} { display:block !important }
    .flex\@@{breakpoint} { display:flex !important }
});


// container

.first > :first-child { margin-top:0 !important }
.last > :last-child { margin-bottom:0 !important }

.first-last {
	> :first-child { margin-top:0 !important }
	> :last-child { margin-bottom:0 !important }
}


// object fit

.object-fit, .object-fit--cover {
	object-fit:cover;
	object-position:50% 50%;
	width:100%; height:100%;
}

.object-fit--contain {
	&:extend(.object-fit);
	object-fit:contain;
}

.object-fit--absolute {
	&:extend(.object-fit);
	&:extend(.absolute-fit);
}

.absolute-fit {
	position: absolute;
	top:0; right:0; bottom:0; left:0;
}